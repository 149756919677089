@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  input[type="number"].hide-arrow::-webkit-inner-spin-button,
  input[type="number"].hide-arrow::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
}

@layer components {
  .default-button {
    @apply inline-flex items-center justify-center rounded-button text-sm font-medium transition-colors focus:outline-none focus:ring-0 focus:ring-neutral-200 dark:focus:ring-neutral-400 disabled:pointer-events-none dark:focus:ring-offset-neutral-900 data-[state=open]:bg-neutral-100 dark:data-[state=open]:bg-neutral-800
  }
}

.hide-arrows::-webkit-outer-spin-button,
.hide-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

:root {
  /* primary colors*/
  --primary-color-lightest:#FFEFE4;
  --primary-color-lighter:#FFBE96;
  --primary-color-light:#FF792E;
  --primary-color-main:#FF5C00;
  --primary-color-dark:#C2410C;
  --primary-color-darker:#9A3412;
  /* base colors */
  --black:#000000;
  --white:#FFFFFF;
  /* neutral colors*/
  --neutral-50: #FAFAFA;
  --neutral-100:#F5F5F5;
  --neutral-200:#E5E5E5;
  --neutral-300:#D4D4D4;
  --neutral-400:#A3A3A3;
  --neutral-500:#737373;
  --neutral-600:#525252;
  --neutral-700:#404040;
  --neutral-800:#262626;
  --neutral-900:#171717;
  /* success */
  --success-light:#A9D4B9;
  --success-main:#15803D;
  --sucess-dark:#14532D;
  /* warning colors*/
  --warning-light:#FEF9C3;
  --warning-main:#FACC15;
  --warning-dark:#CA8A04;
  /* error colors*/
  --error-light:#FECACA;
  --error-main:#DC2626;
  --error-dark:#991B1B;
  /* info colors*/
  --info-light:#BFDBFE;
  --info-main:#3B82F6;
  --info-dark:#1D4ED8;
}
html,
body {
  height:100%;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

li p {
  margin: 0; /* Remove margins around the paragraph */
  display: inline; /* Optional: Ensure it's inline */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.gallery {
  scroll-padding-left: max(calc(50% - theme(spacing.view) / 2 + theme(spacing.4)),theme(spacing.4));
  scroll-padding-right: max(calc(50% - theme(spacing.view) / 2 + theme(spacing.4)),theme(spacing.4));
  padding-left: max(calc(50% - theme(spacing.view) / 2 + theme(spacing.4)),theme(spacing.4));
  padding-right: max(calc(50% - theme(spacing.view) / 2 + theme(spacing.4)),theme(spacing.4));
}

.view {
  max-width: theme(spacing.view);
}

.image-gradient {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)
}

.dark-gradient-overlay {
  background: linear-gradient(0deg, rgba(23, 23, 23, 0.30) 0%, rgba(23, 23, 23, 0.30) 100%)
}


#map img { 
  max-width: none;
   margin: 0
  }

/* Toast styles begin */
.ToastViewport {
  --viewport-padding: 25px;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: 10px;
  width: 390px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 200;
  outline: none;
}

@keyframes move-rtl {
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(calc(-100% - 48px));
  }
}

.ToastRoot {
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ToastRoot[data-state='open'] {
  animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.ToastRoot[data-state='closed'] {
  animation: hide 100ms ease-in;
}
.ToastRoot[data-swipe='move'] {
  transform: translateX(var(--radix-toast-swipe-move-x));
}
.ToastRoot[data-swipe='cancel'] {
  transform: translateX(0);
  transition: transform 200ms ease-out;
}
.ToastRoot[data-swipe='end'] {
  animation: swipeOut 100ms ease-out;
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes swipeOut {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
}

.ToastTitle {
  font-weight: 500;
  color: var(--slate12);
  font-size: 15px;
}

.ToastDescription {
  margin: 0;
  color: var(--slate11);
  font-size: 13px;
  line-height: 1.3;
}
/* Toast styles end */


 .deal-slider > .slick-list {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}

.how-it-works-slider > .slick-list > .slick-track {
  display: flex;
}

.how-it-works-slider > .slick-list > .slick-track > .slick-slide {
  height:inherit
}

.how-it-works-slider > .slick-list > .slick-track > .slick-slide > div {
  height:100%
}

 .slider-navigation > .slick-list > .slick-track > .slick-slide {
  margin: 0 3px;
}

 .slider-navigation > .slick-list > .slick-track {
  width: 100%!important;
}

@media screen and (max-width: 767px) {
   .deal-slider > .slick-list {
    max-width: 100%;
    max-height: 100%;
  }

   .deal-slider > .slick-list > .slick-track {
    display: flex!important;
  }

  .item-slider >.slick-list > .slick-track {
    margin-left: 24px;
    margin-right: 24px;
  }

  .deal-slider > .slick-list > .slick-track > .slick-slide {
    margin: 0 6px;
  }

  .popoverWrapper [data-radix-popper-content-wrapper] {
    transform: translate(0, 0)!important;
    z-index: 99!important;
  }
}

.shadow-poinz-card {
  box-shadow: 4px 4px 12px 0px rgba(23, 23, 23, 0.16);
}